<template>
  <b-card>
    <div class="m-2">
      <!-- Table Top -->
      <b-row>
        <!-- Per Page -->
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <label>{{ $t("eachPage") }}</label>
          <v-select
            v-model="perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block mx-50"
          />
          <label> {{ $t("piece") }} </label>
        </b-col>

        <!-- Search -->
        <b-col cols="12" md="6">
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
              class="d-inline-block mr-1"
              :placeholder="$t('search')"
              :data="searchTerm"
              @input="search"
            />
            <b-button variant="success" @click="updatePrices()">{{
              $t("update")
            }}</b-button>
          </div>
        </b-col>
      </b-row>
    </div>

    <div>
      <b-form
        ref="form"
        :style="{ height: trHeight }"
        class="repeater-form"
        @submit.prevent="repeateAgain"
      >
        <b-table
          v-if="true"
          ref="refPricesTable"
          :items="filteredPrices"
          striped
          :small="true"
          :fields="fields"
          responsive
          class="editable-table"
          primary-key="ikey"
          bordered
          show-empty
          :empty-text="$t('noRecords')"
          :per-page="perPage"
          :current-page="currentPage"
        >
          <template #cell(price)="{ item }">
            <div class="editable text-right">
              <b-form-input
                :id="item.vade0name + '-' + item.id"
                v-model="item.price"
                class="text-right"
                type="number"
                :options="{
                  numeral: true,
                }"
                @input="changedItem(item)"
              />
            </div>
          </template>
          <template #cell(vade1)="{ item }">
            <div class="editable text-right">
              <b-form-input
                :id="item.vade1name + '-' + item.id"
                v-model="item.vade1"
                class="text-right"
                :value="item.vade1"
                type="number"
                @input="changedItem(item)"
              />
            </div>
          </template>
          <template #cell(vade2)="{ item }">
            <div class="editable text-right">
              <b-form-input
                :id="item.vade2name + '-' + item.id"
                v-model="item.vade2"
                class="text-right"
                :value="item.vade2"
                type="number"
                @input="changedItem(item)"
              />
            </div>
          </template>
          <template #cell(vade3)="{ item }">
            <div class="editable text-right">
              <b-form-input
                :id="item.vade3name + '-' + item.id"
                v-model="item.vade3"
                class="text-right"
                :value="item.vade3"
                type="number"
                @input="changedItem(item)"
              />
            </div>
          </template>
          <template #cell(vade4)="{ item }">
            <div class="editable text-right">
              <b-form-input
                :id="item.vade4name + '-' + item.id"
                v-model="item.vade4"
                class="text-right"
                :value="item.vade4"
                type="number"
                @input="changedItem(item)"
              />
            </div>
          </template>
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="
                d-flex
                align-items-center
                justify-content-center justify-content-sm-start
              "
            >
              <span class="text-muted"
                >{{ $t("showing") }} {{ dataMeta.from }} {{ $t("to") }}
                {{ dataMeta.to }} {{ $t("of") }} {{ dataMeta.of }}
                {{ $t("entries") }} </span
              >
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="
                d-flex
                align-items-center
                justify-content-center justify-content-sm-end
              "
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="filteredPrices.length"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-form>
    </div>
  </b-card>
</template>

<script>
import {
  BForm,
  BFormInput,
  BButton,
  BCard,
  BTable,
  BRow,
  BCol,
  BPagination,
} from "bootstrap-vue";
import { heightTransition } from "@core/mixins/ui/transition";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import vSelect from "vue-select";

export default {
  components: {
    BForm,
    BButton,
    BFormInput,
    BCard,
    BTable,
    vSelect,
    BRow,
    BCol,
    BPagination,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      prices: [],
      changedItems: [],
      filteredPrices: [],
      searchTerm: "",
      fields: [],
      perPageOptions: [5, 10, 25, 50],
      perPage: 10,
      currentPage: 1,
    };
  },
  computed: {
    dataMeta() {
      const localItemsCount = this.filteredPrices.length;
      const to = this.perPage * this.currentPage < localItemsCount;
      return {
        from: this.perPage * (this.currentPage - 1) + (localItemsCount ? 1 : 0),
        to: to
          ? this.perPage * (this.currentPage - 1) + this.perPage
          : localItemsCount,
        of: this.prices.length,
      };
    },
  },
  async mounted() {
    this.prices = await this.getPrices();
    this.search();
    this.fields = [
      {
        key: "ingredientname",
        label: this.prices[0].vade0name,
      },
      {
        key: "price",
        label: this.prices[0].vade0name,
      },
      {
        key: "vade1",
        label: this.prices[0].vade1name,
      },
      {
        key: "vade2",
        label: this.prices[0].vade2name,
      },
      {
        key: "vade3",
        label: this.prices[0].vade3name,
      },
      {
        key: "vade4",
        label: this.prices[0].vade4name,
      },
    ];
  },
  methods: {
    search(val) {
      if (val == undefined) val = this.searchTerm;
      this.searchTerm = val;
      if (
        this.searchTerm !== "" &&
        this.searchTerm != null &&
        this.searchTerm != undefined
      )
        this.filteredPrices = this.prices.filter((x) =>
          x.ingredientname.toLowerCase().includes(this.searchTerm.toLowerCase())
        );
      else this.filteredPrices = this.prices;
    },
    async getPrices() {
      return (await this.$http.get("/Ingredients/GetProductPrices/")).data
        .resultdata;
    },
    changedItem(item) {
      var index = this.changedItems.map((x) => x.id).indexOf(item.id);
      if (index == -1) this.changedItems.push(item);
      else this.changedItems.splice(index, 1, item);
    },
    async updatePrices() {
      var besli = this.changedItems.map((x) => {
        return {
          id: x.id,
          price: parseFloat(x.price),
          vade1: parseFloat(x.vade1),
          vade2: parseFloat(x.vade2),
          vade3: parseFloat(x.vade3),
          vade4: parseFloat(x.vade4),
        };
      });
      var result = await this.$http.post(
        "/Ingredients/UpdateProductPrices/",
        besli
      );
      if (result.data.status == "OK")
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: this.$t("successful"),
            icon: "CheckIcon",
            variant: "success",
            text: this.$t("updated", { type: this.$t("prices") }),
          },
        });
      else
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: this.$t("unsuccessful"),
            icon: "AlertTriangleIcon",
            variant: "danger",
            text: this.$t("notUpdated", { type: this.$t("prices") }),
          },
        });
      this.prices = await this.getPrices();
      this.search();
    },
  },
};
</script>

<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: 0.35s height;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
